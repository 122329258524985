.yardContainer {
	margin-left: auto;
	margin-right: auto;
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.releaseContainer {
	display: flex;
	flex-direction: row;
	gap: 3rem;
}
.inventoryInfo {
	width: 80%;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}
.releaseTable {
	border: 8px solid var(--main-bg-color);
	border-spacing: 0;
	border-collapse: separate;
	border-radius: 10px;
	border-left: none;
	border-right: none;
	height: fit-content;
}
.releaseTable td,
th {
	padding: 8px;
	text-align: center;
}
.releaseTable td {
	font-weight: 700;
}
.releaseTitle {
	margin-bottom: 10px;
	font-weight: 500px;
	text-align: center;
}
.availableContainer {
	display: flex;
	flex-direction: column;
	padding: 15px;
	align-content: center;
}
.holdContainer {
	display: flex;
	flex-direction: column;
	padding: 15px;
}
.soldContainer {
	display: flex;
	flex-direction: column;
	padding: 15px;
}
.yardTable {
	overflow-y: auto;
	min-width: min-content !important;
	max-width: min-content !important;
	align-self: center;
}
.yardHead {
	text-align: center;
	margin-bottom: 3px;
}
.companyHead {
	padding-top: 1px;
	height: 20px;
}

.yardTable > tbody {
	height: 100px;
	overflow-y: auto;
}
