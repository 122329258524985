.navbar {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  height: 100%;
  width: 90%;
  margin: auto;
}
.navbar ul {
  display: flex;
  padding: 0px;
}
.navbar ul li {
  list-style-type: none;
  padding-left: 10px;
  padding-right: 10px;
}
.navbar img {
  padding: 5px;
}
.navbar a {
  padding: 5px;
  text-decoration: none;
  color: black;
  font-size: 18px;
  font-weight: 500;
}
.navbar a:hover {
  color: var(--main-bg-color);
}
.profileContainer {
  width: 60px;
}
.profileDropdown {
  z-index: 3;
  width: 200px;
  white-space: wrap;
  text-align: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  transform: translate(-40%, 0);
  background-color: var(--alternate-bg-color);
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
  font-weight: 600;
}
.logoutBtn {
  font-size: 12px !important;
  padding: 3px !important;
  margin-top: 10px;
}
.navbar nav {
  margin-right: 130px;
}
