.update {
	background-color: white;
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.update tr:nth-child(even) {
	background-color: var(--alternate-bg-color);
}
.update td {
	width: fit-content;
}
.update input[type="text"] {
	padding-top: 5px;
	padding-bottom: 5px;
	border: 1px solid black;
}
.update input[type="email"] {
	padding-top: 5px;
	padding-bottom: 5px;
	border: 1px solid black;
}
.update input[type="phone"] {
	padding-top: 5px;
	padding-bottom: 5px;
	border: 1px solid black;
}
.editSubTitle {
	font-weight: bold;
}
.editBtn {
	width: 100%;
	height: 100%;
	border: 2px solid black;
	font-size: 24px;
	font-weight: bold;
	border-radius: 10px;
	border-color: var(--alternate-bg-color);
	background-color: white;
}
.editBtn:hover {
	background-color: var(--main-bg-color);
}
.obDate {
	font-family: "Inter", sans-serif;
	font-weight: 500;
	border: 1px solid black;
	border-radius: 10px;
	padding: 5px;
	width: 93%;
	font-size: 15px;
}
